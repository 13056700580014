import { doc, getFirestore, setDoc } from "firebase/firestore"
import { Button } from "react-bootstrap"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa"
import styled from "styled-components"

export const FeedbackOnFeedback = (props) => {
  let feedbackonFeedbackDocPath = `users/${props.user.uid}/${props.qtrId}/${props.psetId}/feedbackOnFeedback/${props.qId}`
  const db = getFirestore()
  let feedbackonFeedbackRef = doc(db, feedbackonFeedbackDocPath)
  const [feedbackonFeedbackData, isLoading] = useDocumentData(feedbackonFeedbackRef)
  const feedbackData = props.feedbackData
  const isHuman = feedbackData.isValidated
  
  const submitFeedbackOnFeedback = (feedback) => {
    console.log('submitting feedback on feedback', feedback)  
    setDoc(feedbackonFeedbackRef, {
      feedbackOnFeedback: feedback
    }, { merge: true })
  }

  if(isLoading) {
    return <></>
  }


  if(isHuman) {
    return <FeedbackContainer>
      <span className="mr-2">Was this feedback helpful? </span>
      <FeedbackButton 
        feedbackValue="thumbsUp" 
        icon={<FaThumbsUp/>} 
        submit = {(e) => submitFeedbackOnFeedback(e)} 
        data = {feedbackonFeedbackData}
      />
      <FeedbackButton 
        feedbackValue="thumbsDown" 
        icon={<FaThumbsDown/>} 
        submit = {(e) => submitFeedbackOnFeedback(e)} 
        data = {feedbackonFeedbackData}
      />
    </FeedbackContainer>
  }
  return <></>
}

const FeedbackButton = ({feedbackValue, icon, submit, data}) => {
  const onClick = () => {
    submit(feedbackValue)
  }

  const currValue = data?.feedbackOnFeedback

  const variant = currValue === feedbackValue ? "primary" : "outline-secondary"

  return <StyledButton 
    variant={variant} 
    onClick={()=>onClick()}
    size={"sm"}
  >{icon}</StyledButton>
}

const StyledButton = styled(Button)`
  margin-left: 5px;
`

const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
`