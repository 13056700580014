export const SlotSelection = ({ availableSlots, handleSlotSelection }) => {
  const sortedSlotsByDay = sortSlotsByDay(availableSlots);


  return (
    <div>
      
      {Object.keys(sortedSlotsByDay).map((day) => (
        <div key={day} className="mb-4">
          <h5>{day}</h5>
          <div className="row">
            <div className="col-12">
              <select
                className="form-select"
                onChange={(e) => handleSlotSelection(e.target.value)}
              >
                <option value="" disabled selected>Select a time slot</option>
                {sortedSlotsByDay[day].map((slot) => {
                  const slotList = slot.slotList;
                  const randomlyChoseId = randomChoice(slotList);
                  return (
                    <option key={randomlyChoseId} value={randomlyChoseId}>
                      {slot.time} {/* Display time for each slot */}
                    </option>
                  );
                })}
              </select>
              <ShowOnlineWarning day={day} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ShowOnlineWarning = ({ day }) => {
  // turned off!
  return <></>
  if (day.includes("(Online)")) {
    return (
      <span className="text-danger">
          * Online slots are primarily for folks who are SCPD or off campus.
      </span>
    );
  }
  return null;
}


// Sort slots first by full day (including prefixes like "(Online) Tuesday, Oct 22") and then by time
const sortSlotsByDay = (availableSlots) => {
  const sortedSlots = {};
  
  Object.keys(availableSlots).forEach((time) => {
    // Regex to match the entire day part including "(Online)" or similar prefix, and the full date
    const matches = time.match(/^.*?,\s\w+\s\d+/);
    const dayWithDate = matches ? matches[0] : time.split(',')[0]; // Extract the full day with prefix and date

    if (!sortedSlots[dayWithDate]) {
      sortedSlots[dayWithDate] = [];
    }

    sortedSlots[dayWithDate].push({ time, slotList: availableSlots[time] });
  });

  // Sort by placing (Online) entries at the end
  const sortedKeys = Object.keys(sortedSlots).sort((a, b) => {
    const isOnlineA = a.includes("(Online)");
    const isOnlineB = b.includes("(Online)");

    if (isOnlineA && !isOnlineB) return 1;  // (Online) comes last
    if (!isOnlineA && isOnlineB) return -1; // Non-(Online) comes first
    return a.localeCompare(b); // Otherwise, compare alphabetically
  });

  // Sort slots within each day by time
  sortedKeys.forEach((dayWithDate) => {
    sortedSlots[dayWithDate].sort((a, b) => {
      const [hourA, minuteA] = parseTime(a.time);
      const [hourB, minuteB] = parseTime(b.time);
      return hourA - hourB || minuteA - minuteB;
    });
  });

  // Reconstruct sorted object with the sorted keys
  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = sortedSlots[key];
  });

  return sortedObject;
};

// Parse the time from the string, extract hours and minutes, and handle AM/PM
const parseTime = (timeString) => {
  const timeMatch = timeString.match(/\d+:\d+\s[APM]+/i); // Match the time in "6:00 PM" format
  const time = timeMatch ? timeMatch[0] : '12:00 AM'; // Default to 12:00 AM if no match
  const [timePart, meridiem] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (meridiem.toUpperCase() === 'PM' && hours < 12) hours += 12;
  if (meridiem.toUpperCase() === 'AM' && hours === 12) hours = 0; // Handle 12 AM

  return [hours, minutes];
};

const randomChoice = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};
