
import React, { } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PSetPage } from "./pset/PSetPage.js"
import { PSetHome } from "./pset/PSetHome.js"
import { PSetPrint } from "./pset/PSetPrint.js"
import { Authenticated } from "./components/general/auth/Authenticated";
import { Profile } from "./profile/Profile.js"
import { Home } from './home/Home.js'
import { GradingHome } from './grading/GradingHome.js'
import { GradingPage } from './grading/GradingPage.js'
import { GradingStudent } from './grading/GradingStudent.js'
import PyodideProvider from './components/pyodide/PyodideProvider';
import { GradingStudentSummary } from './grading/GradingStudentSummary.js'
import { PSetHistory } from './pset/PSetHistory.js'
import { HomeRedirect } from "HomeRedirect.js";
import { PublicHome } from "public/publichome/PublicHome.js";
import { PublicCoursePage } from "public/PublicCoursePage.jsx";
import { MusicSplash } from "music/MusicSplash.js";
import { PersonalizedMidtermPrep } from "personalizedExamPrep/signup/PersonalizedMidtermPrep.jsx";
import { ExamPrepTeacherSplash } from "personalizedExamPrep/teacherView/ExamPrepTeacherSplash.jsx"
import { ExamPrepTeacherPage } from "personalizedExamPrep/teacherView/ExamPrepTeacherPage.jsx";
import { ProgressPage } from "progress/ProgressPage.jsx";
import { ExamPrepTeacherOuter } from "personalizedExamPrep/teacherView/ExamPrepTeacherOuter.jsx";
import { MidtermPrepStudentView } from "personalizedExamPrep/studentView/MidtermPrepStudentView.jsx";
import { MusicRatingsList } from "music/MusicRatingsList.js";
import { MusicEliminationBrackets } from "music/MusicEliminationBrackets.js";
import { PersonalizedFinalPrep } from "personalizedExamPrep/signup/PersonalizedFinalPrep.jsx";
import { FinalPrepStudentView } from "personalizedExamPrep/studentView/FinalPrepStudentView.jsx";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<>Error</>}>


      {/* 
        TODO: I believe there is an option to pull 
        :qtrId out into the outer router and then start pre
        loading while the user is authenticating.... 
      */}

      <Route element={<Authenticated component={Outlet} />}>

        {/* Music */}
        <Route path="/:qtrId/music" element={<MusicSplash />} />
        <Route path="/:qtrId/music/ratings" element={<MusicRatingsList />} />
        <Route path="/:qtrId/music/brackets" element={<MusicEliminationBrackets />} />

        {/* PEP */}
        <Route path="/:qtrId/pep/midterm/signup" element={<PersonalizedMidtermPrep />} />
        <Route path="/:qtrId/pep/final/signup" element={<PersonalizedFinalPrep />} />
        <Route path="/:qtrId/pep/:examId/ta" element={<ExamPrepTeacherOuter />}>
          <Route index element={<ExamPrepTeacherSplash />} />
          <Route path=":studentId" element={<ExamPrepTeacherPage />} />
        </Route>
        <Route path="/:qtrId/pep/midterm/guide/:studentId" element={<MidtermPrepStudentView />} />
        <Route path="/:qtrId/pep/final/guide/:studentId" element={<FinalPrepStudentView />} />

        {/* Check Progress */}
        <Route path="/progress/:qtrId/:psetId" element={<ProgressPage />} />
        <Route path="/studentSummary/:qtrId/:psetId/:qId" element={<GradingStudentSummary />} />

        {/* Grading */}
        <Route path="/grading/:qtrId/:psetId/:qId/:studentId" element={<GradingStudent />} />
        <Route path="/grading/:qtrId/:psetId/:qId" element={<GradingPage />} />
        <Route path="/grading/:qtrId/:psetId" element={<GradingHome />} />

        {/* PSet */}
        <Route path="/history/:qtrId/:psetId/:qId/:forcedStudentId?" element={<PSetHistory />} />
        <Route path="/:qtrId/:psetId/print" element={<PSetPrint />} />
        <Route path="/:qtrId/:psetId/:qId/:forcedStudentId?" element={<PSetPage />} />
        <Route path="/:qtrId/:psetId/" element={<PSetHome />} />
        <Route path="/:qtrId" element={<Home />} />
        <Route path="/" element={<HomeRedirect />} />
      </Route>
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

