import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

const midtermMsg = `it was such a pleasure to meet with you today. Thanks so much for taking your time! Best of luck getting ready for the midterm this coming week. I am rooting for you and I hope you both rock the exam and enjoy the process of taking a deep dive into the material. I have some light notes on how to think about studying the core concepts for you and then I included a ton of pro tips and study todo's compiled by the whole teaching team.`

const finalMsg = `it was lovely to meet with you today. We are rooting for you as you prep for the final exam this week. Here are a few notes on what topics to prioritize when you start studying, plus all the advice we could think up to give you. You are going to rock the exam!`   

export const PersonalNote = ({taScores, firstName, taScoresPath, examId = 'midterm'}) => {
  console.log(taScores)
  const midtermNote = `Hey ${firstName},` + midtermMsg
  const finalNote = `Hey ${firstName},` + finalMsg
  const defaultNote = examId === 'midterm' ? midtermNote : finalNote

  const defaultNoteRef = useRef(defaultNote);

  const serverNote = taScores?.personalNote
  const [note, setNote] = useState(serverNote || defaultNote)
  const [debouncedNote] = useDebounce(note, 500)

  useEffect(() => {
    if(debouncedNote !== defaultNoteRef.current) {
      console.log(debouncedNote)
      setDoc(doc(getFirestore(), taScoresPath), { personalNote: debouncedNote }, { merge: true });
    }

  }, [debouncedNote])
  return <textarea
    className="w-100"
    rows={5} 
    value={note}
    onChange={(e) => setNote(e.target.value)}
  />
}

export const StudentPersonalNote = ({ personalNote, hasPersonalNote, userFirstName, examId }) => {
  const midtermNote = `Hey ${userFirstName},` + midtermMsg
  const finalNote = `Hey ${userFirstName},` + finalMsg
  const defaultNote = examId === 'midterm' ? midtermNote : finalNote
  if (!hasPersonalNote) {
    return <div className="alert alert-primary">
    <b>Notes from the meeting:</b> {defaultNote}
    </div>
  }
  return <div className="alert alert-primary">
    <b>Notes from the meeting:</b> {personalNote}
  </div>
}