import React, { useState, useEffect } from 'react';
import { CodeOutputSplit  } from '../../components/questionParts/CodeOutputSplit.js';
import { CodeAgent } from '../../components/questionParts/CodeAgent.js';
import {CodeCanvasSplit} from '../../components/questionParts/CodeCanvasSplit.js'
import { CodeGraphSplit } from '../../components/questionParts/CodeGraphSplit.js';
import { RichTextEditor } from '../../components/richText/RichTextEditor.js';

export const AnswerExplanation = (props) => {
    let firebaseDocPath = `users/${props.user.uid}/${props.qtrId}/${props.psetId}/answers/${props.qId}`
    const uniqueKey = `${firebaseDocPath}-${props.editable}`
    useEffect(() => {
      console.log("***** AnswerExplanation MOUNTED *****", uniqueKey);
      return () => {
        console.log("***** AnswerExplanation UNMOUNTED *****");
      };
    }, []);
    return <AnswerExplanationSafe {...props}
      firebaseDocPath={firebaseDocPath}
      key={uniqueKey}
    />
}

const AnswerExplanationSafe = (props) => {
    if(props.questionType.includes('agent')) {
      return <CodeAgent {...props} />
    }
    if(props.questionType.includes('graph')) {
      return <CodeGraphSplit {...props} />
    }
    if(props.questionType.includes('canvas')) {
      return <CodeCanvasSplit {...props} />
    }
    if(props.questionType.includes('code')) {
      return <CodeOutputSplit {...props} />
    }
    if(props.questionType.includes('none')) {
      return <></>
    }
    return <RichTextEditor {...props}/>
  }