import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce/lib';
import {
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
} from "firebase/firestore";
import deepEqual from 'fast-deep-equal'
import { auth, firestore } from "firebaseApp.js";
import { v4 as uuidv4 } from 'uuid';



export const useExplanationDebounced = (documentPath, editable, starterCode, sessionId) => {
  

  // we keep track of the source of truth for the explanation
  // as well as the source of truth for whether the data is loading (aka not ready)
  const [currExplanation, setCurrExplanation] = useState('')
  const [studentDataLoading, setStudentDataLoading] = useState(true)
  const tabId = useRef(uuidv4())

  // load from the database
  var [studentData, serverDataLoading, studentDataError] = useDocumentData(doc(getFirestore(), documentPath));

  // Reactive effect to track the student data loading state
  useEffect(() => {
    // as soon as the student code is not empty, we are ready
    if (currExplanation) {
      setStudentDataLoading(false)
    }
  }, [currExplanation])

  // Handle any changes from the server!
  useEffect(() => {

    // called when the studentData is loaded or changed on server
    if (!serverDataLoading) {

      // First, make sure that the data is not from this tab
      if (studentData?.lastEditTabId === tabId.current) {
        // incoming changes are ignored because they are from this tab
        // this prevents a circular loop of updates
        return;
      }

      // If the server does have fresh data, we update our explanation
      if (studentData?.explanation) {
        // this will be called only if 
        // (1) the student has an explanation and 
        // (2) it is not falsey
        setCurrExplanation(studentData['explanation'])
      } else if (starterCode) {
        // if the student has no explanation, we set the starter code
        console.log('set starter code')
        setCurrExplanation(starterCode)
      } else {
        console.error('student has no solution and there is no starter code')
      }
    } 
  }, [studentData, serverDataLoading])

  //----- Debounce the explanation before you send updates to the database -----

  // we dont want to write when the data first loads
  // useRef object will persist for the full lifetime of the component.
  const isFirstDebounce = useRef(false);
  useEffect(() => {
    // starts false! This is only turned on when a change comes form someone else...
    isFirstDebounce.current = false
  }, [])

  // debouncedExplanation is a lagged version of currExplanation (by 250ms). When it changes, we'll update the database with the explanation
  const [debouncedExplanation] = useDebounce(currExplanation, 250);

  // Whenever our debouncedInputData changes, we need to update the database
  useEffect(() => {
    if (debouncedExplanation) {

      // only save if this isn't the data we just loaded from the db
      // option for optimization: you could short circuit the deepequal.

      // dont save if there hasn't been a change
      let oldExplanation = studentData?.explanation
      let isOriginal = deepEqual(oldExplanation, currExplanation)
      let shouldSave = !isOriginal && editable
      if (!isOriginal && editable) {
        isFirstDebounce.current = false
      }
      if (shouldSave) {
        console.log('saving...')
        isFirstDebounce.current = false
        firestore.doc(documentPath).set(
          {
            explanation: currExplanation,
            lastAuthorSessionId: sessionId,
            lastEdit: new Date(),
            lastEditTabId: tabId.current
          },
          { merge: true }
        )
          .catch(() => {
            // note that this is not fired when the internet is turned off!!
            // https://stackoverflow.com/questions/60850409/firebase-set-object-in-firestore-never-catches-errors
            alert('error')
          })
      }
    }
  }, [debouncedExplanation]);

  return [currExplanation, studentDataLoading, studentDataError, setCurrExplanation]
}