import SortableTable from "components/tables/SortableTable";
import { useUser } from "contexts/UserContext";
import { doc, getDoc, getDocs, getFirestore } from "firebase/firestore";
import { useQuarterId } from "hooks/UseQuarterId";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Link, useParams } from "react-router-dom";
import { PepTeacherContext } from "./ExamPrepTeacherContext";
import { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

export const ExamPrepTeacherSplash = () => {

  console.log("ExamPrepTeacherSplash")
  const qtrId = useQuarterId();
  const firestore = getFirestore();
  const examId = useParams().examId;
  console.log(examId)
  const {
    slotsData,
    slotToStudent,
    classData,
    signupsData,
    isPepTeacherContextLoading,
    isPepTeacherContextError
  } = useContext(PepTeacherContext);

  const [publishedStatuses, setPublishedStatuses] = useState({});

  // Fetch all taScores for each student in parallel
  const fetchTaScoresForStudents = async () => {


    const promises = [];

    // Loop through each student and create a promise for fetching taScores
    for (const studentId in signupsData) {
      if (studentId) {
        const taScoresPath = `personalexamprep/${qtrId}/${examId}/students/${studentId}/private/ta/scores`;
        console.log(taScoresPath)
      
        const taScoresDocRef = doc(firestore, taScoresPath);
        // Push the promise to the array
        promises.push(getDoc(taScoresDocRef).then((docSnap) => ({
          studentId,
          taScoresData: docSnap.exists() ? docSnap.data() : null,
        })));
      }
    }

    // Wait for all promises to resolve
    const results = await Promise.all(promises);

    // Process results and update the publishedStatuses map
    const statusMap = {};
    results.forEach(({ studentId, taScoresData }) => {
      console.log(taScoresData)
      statusMap[studentId] = taScoresData?.isPublished || false;
    });

    setPublishedStatuses(statusMap);
  };

  useEffect(() => {
    fetchTaScoresForStudents();
  }, [slotToStudent]);

  console.log(publishedStatuses)

  if (isPepTeacherContextLoading) {
    return <></>;
  }

  if (isPepTeacherContextError) {
    return <p>Error 500: {isPepTeacherContextError}</p>;
  }

  const data = createDataTable(slotsData, slotToStudent, classData, publishedStatuses);

  const timeSortFunction = (a, b) => {
    if (a === b) {
      return 0;
    }

    // Ensure that a and b are valid date strings that can be parsed by Date
    const dateA = new Date(a);
    const dateB = new Date(b);

    // Check if both dateA and dateB are valid Date objects
    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      console.error('Invalid date format:', { a, b });
      return 0; // Fallback: no sorting if invalid dates are encountered
    }

    // Return the difference in time to sort by date
    return dateA.getTime() - dateB.getTime();
  };

  const customSortFunctions = {
    "Time": timeSortFunction,
    "Published": (a, b) => { return 0 }
  };


  return <>
    <SortableTable
      data={data}
      defaultSortKey={"Time"}
      customSortFunctions={customSortFunctions}
    />
  </>
}

const createDataTable = (slotsData, slotToStudent, classData, publishedStatuses) => {

  const studentIdToDataMap = parseClassData(classData);
  const data = []
  for (const slotId in slotsData) {
    const slot = slotsData[slotId]
    const studentId = slotToStudent[slotId]
    const studentData = studentIdToDataMap[studentId]

    data.push({
      'Time': `${slot.date}, ${slot.time}`,
      'TA': slot.ta,
      'Location': slot.location,
      'Student': studentData ? StudentLink(studentData) : '',
      'Student Email': studentData ? studentData.email : '',
      'TA Email': slot.email.toLowerCase(),
      'Published': <PublishedCheck
        publishedStatuses={publishedStatuses}
        studentId={studentId}
      />
    })
  }
  return data
}

const PublishedCheck = ({ publishedStatuses, studentId }) => {

  const isPublished = publishedStatuses[studentId]
  if (isPublished) {
    return <FaCheck />

  }
  return <></>
}

const StudentLink = (studentData) => {
  const qtrId = useQuarterId()
  const examId = useParams().examId
  const url = `/${qtrId}/pep/${examId}/ta/${studentData.uid}`
  return <Link to={url}>{studentData.name}</Link>
}

const parseClassData = (classData) => {
  const students = classData.students
  // students is an array of objects
  const studentIdToDataMap = {}
  for (const student of students) {
    studentIdToDataMap[student.uid] = student
  }
  return studentIdToDataMap
}

